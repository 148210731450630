var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('columns',[_c('column',[_c('page-heading',{attrs:{"heading":"Support Requests"}})],1),_c('column',{staticClass:"is-narrow"},[_c('router-link',{attrs:{"to":{
        name: 'new-support-request'
      }}},[_vm._v(" Contact Support ")])],1)],1),_c('table',{staticClass:"table is-fullwidth"},[_vm._m(0),_c('tbody',_vm._l((_vm.items.data),function(ticket){return _c('tr',{key:ticket.id},[_c('td',[_c('router-link',{attrs:{"to":{
            name: 'support-request',
            params: {
              ticket: ticket.id
            }
          }}},[_vm._v(" "+_vm._s(`#0000${ticket.number}`)+" ")])],1),_c('td',[_vm._v(_vm._s(ticket.type.title))]),_c('td',[_vm._v(_vm._s(_vm._f("asCalendar")(ticket.updated_at)))]),_c('td',[_vm._v(_vm._s(ticket.status))])])}),0)]),(_vm.items.data.length)?_c('pager',{attrs:{"pageable":_vm.items,"context":"Support Request","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.items.data.length,"with-link":"","heading":"There are no support requests available for display.","to":{
            name: 'new-support-request'
          },"to-text":"New Support Request"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"is-narrow"},[_vm._v("Request #")]),_c('th',[_vm._v("Type")]),_c('th',[_vm._v("Active")]),_c('th',[_vm._v("Status")])])])
}]

export { render, staticRenderFns }