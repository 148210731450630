<template>
<div>
  <columns>
    <column>
        <page-heading
          heading="Support Requests"
        />
    </column>
    <column class="is-narrow">
      <router-link :to="{
        name: 'new-support-request'
      }">
        Contact Support
      </router-link>
    </column>
  </columns>
  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th class="is-narrow">Request #</th>
        <th>Type</th>
        <th>Active</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="ticket in items.data" :key="ticket.id">
        <td>
          <router-link :to="{
            name: 'support-request',
            params: {
              ticket: ticket.id
            }
          }">
            {{ `#0000${ticket.number}` }}
          </router-link>
        </td>
        <td>{{ ticket.type.title }}</td>
        <td>{{ ticket.updated_at | asCalendar }}</td>
        <td>{{ ticket.status }}</td>
      </tr>
    </tbody>
  </table>

        <pager v-if="items.data.length" :pageable="items" context="Support Request" jump-controls @nav="goToPage" />

        <no-items-to-display
          :item-count="items.data.length"
          with-link
          heading="There are no support requests available for display."
          :to="{
            name: 'new-support-request'
          }"
          to-text="New Support Request"
        />

</div>
</template>
<script>
import { support as backend } from '@/api'

export default {

  data: () => ({
    loading: true,
    items: {
      data: []
    }
  }),

  beforeCreate() {
    backend.paginatedTickets({
      path: this.$route.fullPath
    }, ({data}) => {
      this.items = data
      this.loading = false
    }, () => {})
  }

}
</script>